@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import 'bootstrap/dist/css/bootstrap.min.css';


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --main-green: #17DAB8;
  --main-blue: #0127B3;
  --main-dark: #282828;
  --main-color: #0127B3;
  --alt-color: #E0F1F8;
  --main-hover: #0d6efd;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

*{
  font-family: 'Quicksand', sans-serif;
}

body {
  color: #333333;
  background: #FFFFFF;
}

.bg-main{
  background: rgb(23,218,184);
  background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
}

.text-main{
  color: var(--main-color);
}

.bg-main-alt{
  background-color: var(--alt-color);
}

.text-main-alt{
  color: var(--alt-color);
}

.heading{
  font-weight: 700;
  font-size: 38px;
  line-height: 1.4;
  color: #060054;
}

nav .btn-main,nav .btn-main-alt{
  /* padding: 0.5rem 1rem 0.5rem 1rem; */
}

input,button,textarea,select{
  outline: none;
}

a{
  text-decoration: none;
}

.btn-main,.btn-main-alt{
  display: inline-block;
  padding: 1.25rem 2rem;
  border-radius: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
  border-radius: 10px;
  transition: .25s;
  line-height: normal;
}

.btn-main{
  background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
  border: 1px solid linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
  color: #FFF;
}

.btn-main:hover{
  background: linear-gradient(135deg, var(--main-hover) 0%, var(--main-blue) 100%);
  color: #FFF;
}

.btn-main-alt{
  background: #e0f2f8;
  color: var(--main-color);
  border: 1px solid #e0f2f8;
}

.btn-main-alt:hover{
  background: #CDEAF4;
  border: 1px solid #CDEAF4;
  --tw-shadow-color: #8EA9CC;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #8EA9CC), var(--tw-ring-shadow, 0 0 #8EA9CC), var(--tw-shadow);
}

.section-title {
  font-weight: 500;
  font-size: 28px;
  color: #222;
  letter-spacing: 1.75px;
  line-height: 38px;
  margin-bottom: 20px;
}

.section-subtitle {
  font-weight: 400;
  font-size: 16px;
  color: #444;
  letter-spacing: 1px;
  line-height: 30px;
  margin-bottom: 30px;
}

section.page-head{
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

section.page-head .page-head-overlay{
    background-image: linear-gradient(135deg, rgba(23, 218, 184, 0.8) 0%, rgba(1, 39, 179, 0.8) 100%); /* Adjust opacity (0.3) as needed */
    padding-top: 8rem;
    padding-bottom: 3rem;
}

section.page-head .page-title{
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 1.4px;
  color: #fff;
  text-align: center;
}

section.page-head .page-subtitle{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #D6D6D6;
  text-align: center;
  margin: auto;
  margin-top: 15px;
}

section.page-head .breadcrumb{
  margin-top: 15px;
  width: 100%;
  justify-content: center;
}

section.page-head .breadcrumb li{
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .3px;
  color: #FFF;
  line-height: 30px;
  display: inline-block;
}

section.page-head .breadcrumb li a {
  color: #FFF;
}

section.page-head .breadcrumb li.active {
  opacity: 0.7;
}

section.page-head .breadcrumb li.breadcrumb-item.active::before {
  color: white;
  font-family: FontAwesome;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 15px;
  padding-top: .5px;
  content: "\f105";
}

.hide-in-sm {
    display: flex !important;
}

.show-in-sm {
    display: none !important;
}

.bg-black {
    background: black !important;
}

.text-black {
    color: black !important;
}

.text-gray {
    color: lightgray !important;
}

.bg-white {
    background: white !important;
}

.text-white {
    color: white !important;
}

a {
    text-decoration: none !important;
}

.rounded-top-10 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rounded-bottom-10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.rounded-40 {
    border-radius: 40px;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-105 {
    font-size: 105%;
}

.font-110 {
    font-size: 110%;
}

.font-95 {
    font-size: 95%;
}

.font-90 {
    font-size: 90%;
}

.font-85 {
    font-size: 85%;
}

.font-80 {
    font-size: 80%;
}


@media only screen and (max-width: 768px) {
  
  .p-sm-0 {
      padding: 0px !important;
  }

  .hide-in-sm {
      display: none !important;
  }

  .show-in-sm {
      display: flex !important;
  }

  .heading {
    font-size: 32px !important;
    line-height: 48px;
  }

  section.page-head .page-head-overlay{
      background-image: linear-gradient(135deg, rgba(23, 218, 184, 0.8) 0%, rgba(1, 39, 179, 0.8) 100%); /* Adjust opacity (0.3) as needed */
      padding-top: 5rem;
      padding-bottom: 3rem;
  }

  main{
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 920px) {
}