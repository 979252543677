section.sales .section-subtitle{
    font-size: 15px;
    color: #666;
    letter-spacing: .25px;
}

section.sales #display-google-map img{max-height:none;max-width:none!important;background:none!important;}

section.sales .map-wrapper > div:nth-child(1){
    max-width:100%;list-style:none; transition: none;overflow:hidden;width:100%;height:500px;
}

section.sales .map-wrapper  iframe{
    width:100%;height:500px;
}

section.sales #display-google-map{
    height:100%; width:100%;max-width:100%;
}

section.sales .contact{
  position: relative;
}

section.sales .contact-info {
    position: absolute;
    z-index: 2;
    width: 30%;
    float: right;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(135deg, var(--main-blue) 0%, var(--main-green) 100%);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 30px;
    padding-bottom: 10px;
  }
  section.sales .contact-info .item {
    overflow: hidden;
    margin-bottom: 30px;
    color: #fff;
  }
  section.sales .contact-info .item i {
    float: left;
    font-size: 16px;
    width: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
  section.sales .contact-info .item span {
    float: left;
    width: calc(100% - 30px);
    width: -webkit-calc(100% - 30px);
    font-size: 14px;
  }
  section.sales .contact-info .item span a {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    section.sales .contact-info {
      position: relative !important;
      width: 100% !important;
      top: 0% !important;
      transform: perspective(1px) translateY(0%) !important;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    }
  }