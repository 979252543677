.navbar {
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.navbar .navbar-brand img{
  height: 70px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

nav .nav-link {
  transition: 0.5s;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .75px;
}

nav .nav-link:hover {
  color: #CCC !important;
}

nav .nav-item {
  margin-left: 15px;
  margin-right: 15px;
}

nav .header-btn {
  margin-left: 15px;
  padding: 5px 25px;
  border: 1px solid #FFFFFF;
  color: #fff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 25px;
  background: none;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .75px;
}

nav .header-btn:before {
  content: "";
  position: absolute;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0.19;
}

nav .header-btn:hover {
  background: white;
  color: var(--main-blue);
}



/* Sticky */

.navbar.sticky {
  background: #fff;
  height: 80px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}

.navbar.sticky .navbar-brand img{
  height: 60px;
}

.navbar.sticky .nav-link {
  color: #333;
}

.navbar.sticky .header-btn {
  border: 1px solid #444;
  color: #222;
}

.navbar.sticky .nav-link:hover {
  color: var(--main-hover) !important;
}

.navbar.sticky .header-btn:hover {
  background:  var(--main-hover);
  color: #FFF;
}

.pt-nav{
  padding-top: 80px;
}

@media (max-width: 991px) {
  .navbar {
      background: #fff;
      height: 80px;
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  }

  .navbar .navbar-brand img {
    height: 60px;
  }


  ul.navbar-nav {
      margin-top: 20px;
      padding-left: 20px;
  }

  div.form-inline {
      padding-left: 20px;
      padding-right: 20px;
  }

  div.form-inline .nav-link {
      padding-left: 8px;
  }

  div.form-inline a.bg-main-alt.btn {
      width: 100%;
      margin-top: 10px;
  }


  nav .divider.header {
      display: none;
  }

  .menu-button {
      z-index: 999;
      width: 52px;
      height: 52px;
      min-width: 52px;
      padding: 0px;
      border-radius: 1000px;
      border: none;
      background-color: transparent;
      -webkit-transition: background-color 350ms ease, -webkit-transform 350ms ease;
      transition: background-color 350ms ease, -webkit-transform 350ms ease;
      transition: transform 350ms ease, background-color 350ms ease;
      transition: transform 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
      color: #fff;
      font-size: 30px;
  }

  nav a.nav-link {
      font-size: 22px !important;
  }

  .form-inline{
    margin-top: 20px;
  }

  .collapse:not(.show) {
    display: block !important;
  }

  .navbar-collapse {
      background: linear-gradient(135deg, var(--main-blue) 0%, var(--main-green) 100%);
      z-index: 999;
      width: 75%;
      top: 0;
      position: fixed;
      right: -75%;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      height: 100%;
      transition: .3s ease-in-out;
  }

  .navbar.sticky .nav-link {
    color: #FFF;
  }

  .navbar.sticky .header-btn {
    border: 1px solid #FFFFFF;
    color: #fff;
  }

  .navbar-collapse.show {
      right: 0;
  }
}
