.main-banner{
    overflow: hidden;
    position: relative;
}

.main-banner .right-bg{
    float: right;
    width: 88%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.main-banner .right-bg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: linear-gradient(135deg, #0127B3 0%, #17DAB8 100%);
}

.main-banner .banner-overlay {
    position: absolute;
    top: 0px;
    min-height: 500px;
    z-index: 2;
}

.main-banner .banner-text {
    position: absolute;
    width: 100%;
    top: 35%;
    transform: perspective(1px) translateY(0%);
    z-index: 4;
}

.main-banner .banner-text h1 {
    font-weight: 400;
    font-size: 42px;
    color: #222;
    line-height: 54px;
    letter-spacing: 1.4px;
    margin-bottom: 40px;
}

.main-banner .banner-text p {
    font-weight: 400;
    font-size: 16px;
    color: #888;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

.main-banner .banner-text .email-box {
    width: 80%;
    position: relative;
    background: none;
    overflow: hidden;
}

.main-banner .banner-text .email-box span {
    display: block;
    margin-top: 54px;
    font-weight: 400;
    font-size: 12px;
    color: #888;
    letter-spacing: 0.67px;
    padding-left: 5px;
}

.main-banner .banner-text .email-box .input input {
    position: absolute;
    width: 100%;
    height: 46px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #CCDFF9;
    outline: none;
    padding-left: 20px;
    padding-right: 120px;
    z-index: 1;
    font-weight: 500;
    font-size: 12px;
    color: #555;
    letter-spacing: 0.67px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .main-banner .banner-text .email-box .input input::-webkit-input-placeholder {
    color: #888;
  }
  .main-banner .banner-text .email-box .input input:-moz-placeholder {
    color: #888;
  }
  .main-banner .banner-text .email-box .input input:focus {
    padding-left: 30px;
  }
  .main-banner .banner-text .email-box .input button {
    position: absolute;
    right: 0px;
    z-index: 2;
    height: 46px;
    border: none;
    background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    border-radius: 0 5px 5px 0;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    width: 98px;
    outline: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    .main-banner{
        background-image: url('../../public/images/home-banner.png');
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 80px;
    }

    .main-banner:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-image: linear-gradient(135deg, #0127B3 0%, #17DAB8 100%);
    }
    
    .main-banner .right-bg{
        display: none;
    }

    .main-banner .banner-text{
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;
    }

    .main-banner .banner-text h1{
        font-weight: 400;
        font-size: 24px;
        color: #FFF;
        line-height: 42px;
        letter-spacing: 1.4px;
        margin-bottom: 20px;
        text-align: center;
    }

    .main-banner .banner-text p {
        font-weight: 400;
        font-size: 16px;
        color: #FFF;
        line-height: 28px;
        letter-spacing: 1px;
        margin-bottom: 20px;
        text-align: center;
    }

    .main-banner .banner-text .email-box {
        width: 100%;
    }
    
    .main-banner .banner-text .email-box span {
        color: #f3f3f3;
        text-align: center;
    }

  }
  
  @media (max-width: 991px){
    .main-banner .banner-overlay {
        display: none;
    }
}



  /* Features */

section.features .single-feature{
    display: block;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 50px 28px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
}

section.features .single-feature .icon i {
    display: block;
    height: 67px;
    line-height: 67px;
    width: 100%;
    font-size: 28px;
    background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

section.features .single-feature h5{
    font-weight: 600;
    font-size: 16px;
    color: #333;
    letter-spacing: 0.7px;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease 0s;
}

section.features .single-feature p{
    font-weight: 400;
    font-size: 14px;
    color: #555;
    letter-spacing: 0.88px;
    line-height: 26px;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease 0s;
    margin-bottom: 30px;
}

section.features .single-feature .button i{
    width: 42px;
    height: 42px;
    background-image: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    line-height: 42px;
    text-align: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    color: #fff;
}

section.features .single-feature:hover{
    background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    margin-top: -15px;
    margin-bottom: 15px;
}

section.features .single-feature:hover .icon i {
    background: #FFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

section.features .single-feature:hover h5{
    color: #FFF;
}

section.features .single-feature:hover p{
    color: #EBEBEB;
}

section.features .single-feature:hover .button i{
    background: #FFF;
    color: var(--main-blue);
}

@media only screen and (max-width: 768px) {
    section.features .single-feature {
        padding: 10px 28px;
    }

    section.features .single-feature .button {
        display: none;
    }

    section.features .single-feature p {
        margin-bottom: 15px;
    }
}


/* Download */

section.download{
    position: relative;
    background-image: url('https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

section.download .download-overlay{
    background-image: linear-gradient(135deg, rgba(23, 218, 184, 0.8) 0%, rgba(1, 39, 179, 0.8) 100%); /* Adjust opacity (0.3) as needed */
}

section.download .btn-download{
    border-radius: 30px;
    border: 1px solid white;
    color: white;
    padding: 10px 50px;
    background: rgba(255,255,255,0.2);
    font-weight: 500;
    letter-spacing: .5px;
    width: 45%;
}
section.download .btn-download i{
    margin-right: 5px;
}

@media only screen and (max-width: 768px) {
    section.download .btn-download{
        width: 90%;
    }
    section.download .btn-download:nth-child(2){
        margin-top: 20px;
    }

    section.download .download-btns{
        display: block !important;
    }
}