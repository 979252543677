footer {
    background: #F4F8FB;
    margin-top: 3rem;
    text-align: left;
}

.text{
    font-weight: 500;
    font-size: 14px;
    color: #888;
    line-height: 26px;
}

.secondary-footer {
    border-top: 1px solid #DAE6FD;
    font-size: 14px;
    font-weight: 500;
}

footer .footer-links h6 {
    font-size: 16px;
    line-height: 1.444em;
    letter-spacing: 0.05em;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 35px;
}

footer .footer-links p,
footer .footer-links p a {
    color: #888;
    font-size: 14px;
    font-weight: 500;
    line-height: 0;
    text-decoration: none;
    transition: 0.3s;
}

footer .footer-links p{
    margin-bottom: .5rem;
    line-height: 24px;
}

footer .footer-links p a i{
    font-size: 12px;
    color: #999;
    margin-right: 5px;
    transition: .3s;
}

footer .footer-links p a:hover i{
    margin-right: 10px;
    color: var(--main-hover);
}

footer p:hover,
footer p a:hover {
    color: var(--main-hover);
}

footer p {
    padding: 0 !important;
}

.footer-social-media-link i {
    text-align: center;
    width: 25px;
    height: 25px;
    min-width: 25px;
    color: white;
    border-radius: 1000px;
    font-size: 13px;
    padding-top: 7px;
    margin-right: 10px;
    background-color: #5E6065;
    transition: 0.5s;
    text-decoration: none;
}

.footer-social-media-link:hover i {
    background-color: #CD8129;
    -webkit-transform: translate(0px, -4px);
    -ms-transform: translate(0px, -4px);
    transform: translate(0px, -4px);
    color: #fff;
}

footer table tr td:nth-child(2) {
    padding-left: 10px;
}

footer table {
    border-collapse: collapse;
}

footer table * {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.143em;
    letter-spacing: 0.05em;
    padding-bottom: 16px;
}

@media only screen and (max-width:786px) {

    footer .col-md-6,
    footer .col-lg-3 {
        text-align: left;
    }
    
    footer .footer-links h6 {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
