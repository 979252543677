.pricing-item {
    background: #FFFFFF;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
  }
  .pricing-item.active .pricing-header {
    background-image: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
  }
  .pricing-item.active .pricing-header .pricing-title {
    color: #fff;
  }
  .pricing-item.active .pricing-header .number {
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.13);
  }
  .pricing-item.active .pricing-header .number span {
    color: var(--main-hover);
  }
  .pricing-item.active .pricing-header .number:before {
    border-bottom: 14px solid #fff;
  }
  .pricing-item.active .pricing-header .number:after {
    border-top: 14px solid #fff;
  }
  .pricing-item.active .pricing-footer a {
    background: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    color: #fff;
  }
  .pricing-item.active .pricing-footer a:hover {
    background: #fff;
    color: var(--main-hover);
  }
  .pricing-item .pricing-header {
    border-bottom: 1px solid #E2EBFF;
    text-align: center;
    height: 130px;
    display: block;
    position: relative;
  }
  .pricing-item .pricing-header .pricing-title {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    color: #333;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
  }
  .pricing-item .pricing-header .number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background-image: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    position: relative;
    bottom: -110px;
    margin: auto;
  }
  .pricing-item .pricing-header .number span {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    display: block;
  }
  .pricing-item .pricing-body {
    margin-bottom: 40px;
  }
  .pricing-item .pricing-body .price-wrapper {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .pricing-item .pricing-body .price-wrapper .currency {
    height: 47px;
    font-weight: 500;
    font-size: 20px;
    color: var(--main-hover);
    position: relative;
    top: -15px;
  }
  .pricing-item .pricing-body .price-wrapper .price {
    font-weight: 700;
    font-size: 34px;
    color: var(--main-hover);
    letter-spacing: 2.12px;
  }
  .pricing-item .pricing-body .price-wrapper .period {
    font-weight: 700;
    font-size: 14px;
    color: var(--main-hover);
    letter-spacing: 0.88px;
  }
  .pricing-item .pricing-body .list {
    padding: 0px;
  }
  .pricing-item .pricing-body .list li {
    list-style: none;
    text-align: center;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #CCC;
    letter-spacing: 0.88px;
    text-decoration: line-through;
  }
  .pricing-item .pricing-body .list li.active {
    color: #555;
    font-weight: 500;
    text-decoration: none;
  }
  .pricing-item .pricing-footer {
    text-align: center;
  }
  .pricing-item .pricing-footer a {
    width: 160px;
    margin: auto;
    display: inline-block;
    height: 36px;
    line-height: 34px;
    text-align: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    border: 1px solid var(--main-hover);
    border-color: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 12px;
    background: #fff;
    color: var(--main-hover);
    letter-spacing: 0.75px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .pricing-item .pricing-footer a:hover {
    background-image: linear-gradient(135deg, var(--main-green) 0%, var(--main-blue) 100%);
    color: #fff;
  }